<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Unidade">
          <listar-unidade />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarUnidade from '@/components/patio/ListarUnidade'
export default {
  data() {
    return {
      page: {
        title: 'Gerenciamento de Unidade',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Unidades',
          disabled: false,
        },
      ],
    }
  },
  components: {
    ListarUnidade,
  },
}
</script>

<style></style>
